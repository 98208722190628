@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 20px;
}

.custom-autocomplete .MuiInputBase-root {
  min-height: 44px; /* Set the desired height */
}
